@font-face {
  font-family: 'SpoqaHanSansNeo-Bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SpoqaHanSansNeo-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SpoqaHanSansNeo-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SpoqaHanSansNeo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  --chakra-fonts-heading : 'SpoqaHanSansNeo-Bold';
  --chakra-fonts-body : 'SpoqaHanSansNeo-Regular';
}

#root {
  margin: auto;
  max-width: 720px;
}